import React from 'react'
import styled from 'styled-components'
import { Flex } from 'fortcake-uikit-v2'
import { useTranslation } from '../../contexts/Localization'

const Wrapper = styled.div<{ $isSide: boolean }>`
  width: 100%;
  height: ${({ $isSide }) => ($isSide ? '100%' : 'auto')};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 16px;
  padding-right: ${({ $isSide }) => ($isSide ? '32px' : '0px')};
  ${({ theme }) => theme.mediaQueries.md} {
    justify-content: space-between;
    flex-direction: ${({ $isSide }) => ($isSide ? 'column' : 'row')};
  }
`

type FooterVariant = 'default' | 'side'

const Footer: React.FC<{ variant?: FooterVariant }> = ({ variant = 'default' }) => {
    const { t } = useTranslation()
    const isSide = variant === 'side'
    return (
        <Wrapper $isSide={isSide}>
            <Flex flexDirection={isSide ? 'column' : ['column', 'column', 'row']} alignItems="center" />
            {isSide && <Flex flexGrow={1} />}
            <Flex
                flexGrow={isSide ? 0 : 1}
                alignItems="center"
                width={['100%', '100%', '100%', isSide ? '100%' : 'auto']}
                justifyContent={['center', 'center', 'center', 'flex-end']}
            />
        </Wrapper>
    )
}

export default Footer
